import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";
import { AuthContext } from "../../managers/Contexts";

let userDataInit = false;

interface User {
  id: string;
  firstName: string;
  lastName: string;
  clockStatus: boolean;
}

interface UserContextType {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

// Create the UserContext with a default value.
const UserContext = createContext<UserContextType | undefined>(undefined);

// Provider component
export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User>({
    id: "",
    firstName: "",
    lastName: "",
    clockStatus: false,
  });

  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (!userDataInit) {
      userDataInit = true;
      const getData = async () => {
        if (
          authContext &&
          authContext.currentUser &&
          authContext.currentUser.email
        ) {
          ERP.Users.GetUserData(authContext.currentUser.email)
            .then((data) => {
              ERP.LocalUser.ClockStatus(
                authContext.currentUser?.email as string
              )
                .then((status) => {
                  setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    clockStatus: status,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        } else userDataInit = false;
      };

      getData();
      /**
        console.log("TIME CLOCK INIT");
        
         */
    }
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
