import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Breadcrumbs, { breadcrumbsClasses } from "@mui/material/Breadcrumbs";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { Link, useLocation } from "react-router-dom";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: theme.palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: "center",
  },
}));

function FormatPathName(name: string) {
  let result = "";
  name.split("/").forEach((path, i) => {
    if (i !== name.split("/").length - 1) {
      result += `${i !== 0 ? "/" : ""}${path}`;
    }
  });
  return result;
}

export default function NavbarBreadcrumbs() {
  const location = useLocation();
  const path = location.pathname.split("/");
  path.shift();
  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      <Link to={"/home"}>
        <Typography variant="body1">KAM Technologies</Typography>
      </Link>
      {path.map((page, i) => (
        <Typography
          key={`${i}`}
          variant="body1"
          sx={{ color: "text.primary", fontWeight: 600 }}
        >
          {i === 0 || i === path.length - 1 ? (
            page.charAt(0).toUpperCase() + page.slice(1)
          ) : (
            <Link to={`${FormatPathName(location.pathname)}`}>
              {page.charAt(0).toUpperCase() + page.slice(1)}
            </Link>
          )}
        </Typography>
      ))}
    </StyledBreadcrumbs>
  );
}
