import { Button, Stack } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

export default function EditTools(props: { isEditing: boolean, toggleEdit: (editing: boolean) => void, onSave: () => void}) {
    return <Stack key={"edittools"} direction="row" spacing={2}>
          {props.isEditing && (
            <Button
              key={"save"}
              variant="outlined"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={props.onSave}
            >
              Save
            </Button>
          )}
          <Button
            key={'toggle'}
            variant="contained"
            color="primary"
            startIcon={props.isEditing ? <CancelIcon /> : <EditIcon />}
            onClick={() => {
              props.toggleEdit(!props.isEditing);
            }}
          >
            {props.isEditing ? 'Cancel' : 'Edit'}
          </Button>
        </Stack>
  }