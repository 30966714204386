import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuContent from "./MenuContent";
import { useContext, useState, useEffect } from "react";
import OptionsMenu from "./OptionsMenu";

import { Chip } from "@mui/material";
import { useUser } from "./Core/UserContext";
const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
  },
});

export default function SideMenu() {
  const { user, setUser } = useUser();

  const toggleClockStatus = () => {
    setUser((prevUser) => ({
      ...prevUser,
      clockStatus: !prevUser.clockStatus,
    }));
  };

  const clockedIn = user.clockStatus;
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          mt: "calc(var(--template-frame-height, 0px) + 4px)",
          p: 1.5,
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            lineHeight: "16px",
            color: "#BC0000",
            textAlign: "center",
          }}
        >
          KAM TECHNOLOGIES
        </Typography>
      </Box>
      <Divider />
      <MenuContent />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Avatar
          sizes="small"
          alt={`${user.firstName} ${user.lastName}`}
          src="/static/images/avatar/7.jpg"
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: "auto" }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, lineHeight: "16px" }}
          >
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            <Chip
              label={clockedIn ? "Clocked In" : "Clocked Out"}
              color={clockedIn ? "success" : "error"}
            />
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack>
    </Drawer>
  );
}
