export interface User extends Object {
  first_name: string;
  last_name: string;
  permission_level: string;
  role: string;
}

export interface Resource {
  name: string;
  type: string;
}

export interface WorkCell {
  name: string;
  id: string;
  wo: string;
  start: string;
  end: string;
  opnum: string;
  group: string;
  quantity_complete: number;
  quantity_total: number;
}

export interface Operation {
  desc: string;
  opNum: number;
  resource: string;
}

export interface Object {
  id: string;
  name: string;
}

export interface Part extends Object {
  name: string;
  id: string;
  customer: string;
  pricePer: number;
  operations: Operation[];
  quantity_inventory?: string;
}

export interface WorkOrder extends Object {
  part: string;
}

export interface RFQ extends Object {
  name: string;
  id: string;
  date: string;
  client: string;
  items: { [key: string]: any }[];
}

export interface Packingslip extends Object {
  name: string;
  id: string;
  cpo: string;
  client: string;
  ship_to_company: string;
  bill_to_company: string;
  redact_shipper: boolean;
  items: { [key: string]: any }[];
}

export interface Contact {
  name: string;
  id: string;
  email: string;
  tax_exempt: boolean;
  address: string;
  phone: string;
  contact: string;
}

export const ObjectProperties: {
  [key: string]: {
    [key: string]: string | boolean | undefined;
    name: string;
    type: string;
    required?: boolean;
    refType?: ObjectType;
  }[];
} = {
  part: [
    {
      name: "name",
      type: "string",
      required: true,
    },
    {
      name: "client",
      type: "ref",
      refType: "contact",
      required: true,
    },
    {
      name: "rev",
      type: "string",
    },
  ],
  contact: [],
  rfq: [
    {
      name: "client",
      type: "ref",
      refType: "contact",
      required: true,
    },
    {
      name: "due",
      type: "string",
      required: true,
    },
  ],
  po: [],
  resource: [],
};

export const TypeDisplayNames = {
  part: "Part",
  cpo: "Client PO",
  vpo: "Vendor PO",
  contact: "Contact",
  resource: "Resource",
  rfq: "RFQ",
  cot: "COTS Item",
  user: "User",
  workorder: "Workorder",
  workcell: "Workcell",
  packingslip: "Packing Slip",
};
export const DocsWithLineItems = ["cpo", "vpo", "rfq", "packingslip"];
export const PrintEnabledDocs = ["rfq", "packingslip"];
export type ObjectType =
  | "part"
  | "contact"
  | "resource"
  | "workorder"
  | "rfq"
  | "cpo"
  | "vpo"
  | "user"
  | "workcell"
  | "cot"
  | "packingslip";
export const ObjectTypeList = [
  "contact",
  "resource",
  "rfq",
  "cpo",
  "vpo",
  "part",
  "cot",
  "packingslip",
];
