import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import FB from "../util/Firebase"
import ERP from "../util/ERP";

interface AuthContextType {
    currentUser: User | null;
}


export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(FB.auth, (user) => {
        if (user) {
          user.getIdToken().then((token) => {
            ERP.LocalUser.SetCredential(user.email as string, token);
          })
        }
        setCurrentUser(user);
      });
  
      return () => unsubscribe();
    }, []);
  
    return (
      <AuthContext.Provider value={{ currentUser }}>
        {children}
      </AuthContext.Provider>
    );
  };
