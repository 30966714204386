import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import Papa, { ParseResult } from 'papaparse';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';

const targets = ['Contacts', 'Parts', 'COTS']; // Example targets

const Import: React.FC = () => {
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleTargetSelection = (target: string) => {
    setSelectedTarget(target);
    setColumns([]);
    setRows([]);
    setSelectedRows([]);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<any>) => {
          setIsUploading(false);

          if (result.data.length > 0) {
            // Extract column definitions from CSV headers
            const colDefs = Object.keys(result.data[0]).map((field) => ({
              field,
              headerName: field,
              flex: 1,
              editable: true,
              sortable: true,
            }));

            // Add row IDs for the grid
            const rowData = result.data.map((row, index) => ({ id: index, ...row }));

            setColumns(colDefs);
            setRows(rowData);
          } else {
            alert('The uploaded file has no data.');
          }
        },
        error: (error) => {
          setIsUploading(false);
          alert('Error parsing file: ' + error.message);
        },
      });
    }
  };

  const validateRow = (row: any): boolean => {
    // Add your validation logic here
    // For example, consider a row valid if all fields are non-empty
    return Object.values(row).every((value) => value && value.toString().trim() !== '');
  };

  const handleSelectionChange = (newSelection: string[]) => {
    setSelectedRows(newSelection);
  };

  return (
    <Box sx={{ p: 4 }}>
      {/* Target Selection */}
      <Box sx={{ mb: 4, display: 'flex', gap: 2 }}>
        {targets.map((target) => (
          <Button
            key={target}
            variant={selectedTarget === target ? 'contained' : 'outlined'}
            onClick={() => handleTargetSelection(target)}
          >
            {target}
          </Button>
        ))}
      </Box>

      {/* Display Selected Target */}
      {selectedTarget && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          Target: {selectedTarget}
        </Typography>
      )}

      {/* Upload CSV Button */}
      {selectedTarget && (
        <Box sx={{ mb: 4 }}>
          <input
            type="file"
            accept=".csv"
            id="file-upload"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />
          <label htmlFor="file-upload">
            <Button variant="contained" component="span">
              Upload CSV File
            </Button>
          </label>
        </Box>
      )}

      {/* Interactive Data Grid */}
      {rows.length > 0 && columns.length > 0 && (
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            getRowClassName={(params) =>
              validateRow(params.row) ? 'valid-row' : 'invalid-row'
            }
            sx={{
              '& .valid-row': { backgroundColor: 'rgba(0, 255, 0, 0.2)' },
              '& .invalid-row': { backgroundColor: 'rgba(255, 0, 0, 0.2)' },
              '& .MuiDataGrid-cell': { padding: '4px' },
              '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f4f4f4' },
            }}
          />
        </Box>
      )}

      {/* Selected Rows Display */}
      {selectedRows.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Selected Rows:</Typography>
          <Typography variant="body2">{selectedRows.join(', ')}</Typography>
        </Box>
      )}

      {/* Uploading Dialog */}
      <Dialog open={isUploading}>
        <DialogTitle>Uploading</DialogTitle>
        <DialogContent className="flex flex-col items-center justify-center">
          <CircularProgress />
          <Typography variant="body1" className="mt-4">
            Parsing CSV...
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Import;
