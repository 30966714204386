import React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

// Define the type for each punch row
interface Punch {
  id: number; // id should be a number or string
  time_in: string;
  time_out?: string;
  time_duration?: number | string;
}

// Props for the component
interface TimeClockTableProps {
  data: Omit<Punch, 'id'>[]; // Incoming data without 'id'
}

// DataGrid columns definition
const columns: GridColDef<Punch>[] = [
  {
    field: 'time_in',
    headerName: 'Time In',
    flex: 1,
    valueFormatter: (params: GridRenderCellParams<Date>) => params.value
  },
  {
    field: 'time_out',
    headerName: 'Time Out',
    flex: 1,
    valueFormatter: (params: GridRenderCellParams) =>
      params.value
  },
  {
    field: 'time_duration',
    headerName: 'Duration (Hours)',
    flex: 1,
    valueFormatter: (params: GridRenderCellParams) =>
     params.value
  },
];

// TimeClockTable Component
const TimeClockTable: React.FC<TimeClockTableProps> = ({ data }) => {
  // Map the data to add an id for DataGrid
  const rows: Punch[] = data.map((punch, index) => ({
    id: index, // Assign the index as the id
    time_in: punch.time_in,
    time_out: punch.time_out || "N/A", // Default to current date if missing
    time_duration: punch.time_duration ? Math.round(((punch.time_duration as number)/3600) * 100)/100 : "ACTIVE", // Default to 0 if missing
  }));

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid<Punch>
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        density="compact"
      />
    </div>
  );
};

export default TimeClockTable;
