import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ERP from '../../util/ERP';
import DynamicProp from '../../util/DynamicProp';
import { notificationManager } from '../../managers/NotificationManager';
import { ObjectType, ObjectTypeList, TypeDisplayNames } from '../../util/Typings';

const CreateObject: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [objectType, setObjectType] = useState<string>(() => {
        // Default to the fragment in the URL or 'part' if no fragment exists
        return location.hash.replace('#', '') || 'part';
    });
    const [formData, setFormData] = useState<{ [key: string]: string | any }>({});
    const [objectScheme, setObjectScheme] = useState<{ [key: string]: string | number | boolean }>({});

    useEffect(() => {
        const getDefault = async () => {
            const objProps = await ERP.GetDefaultObject(objectType as ObjectType);
            setObjectScheme(objProps);
        };
        getDefault();
    }, [objectType]);

    useEffect(() => {
        // Update object type based on URL fragment
        const hash = location.hash.replace('#', '');
        if (hash && ObjectTypeList.includes(hash)) {
            setObjectType(hash);
        }
    }, [location]);

    const handleTypeChange = (type: string) => {
        navigate(`#${type}`);
        setFormData({});
        if (type === 'rfq') {
            setFormData({ date: new Date().toISOString(), items: [] });
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const invalid: string[] = [];
        requiredProps.forEach((propName) => {
            if (!formData[propName]) {
                invalid.push(propName);
            }
        });

        if (invalid.length > 0) {
            notificationManager.notify('error', 'Invalid props: \n' + invalid.join(', \n'));
        } else {
            ERP.CreateObject(objectType as ObjectType, formData)
                .then((newObj) => {
                    navigate(`/objects/${objectType}s/${newObj.id}`);
                })
                .catch((error) => {
                    notificationManager.notify('error', `Server error: ${error}`);
                });
        }
    };

    const requiredProps: string[] = [];
    const otherProps: string[] = [];

    for (const propName in objectScheme) {
        const propValue = objectScheme[propName] as string;
        if (typeof propValue !== 'object') {
            if (typeof propValue === 'string' && propValue.startsWith('!')) {
                requiredProps.push(propName);
            } else {
                otherProps.push(propName);
            }
        }
    }

   
    return (
        <Container>
            <form onSubmit={handleSubmit}>
                {/* Object Type Buttons */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        justifyContent: 'left',
                        mb: 3,
                        
                    }}
                >
                    {ObjectTypeList.map((type, i) => (
                        <Button
                            key={type}
                            variant={type === objectType ? 'contained' : 'outlined'}
                            color={type === objectType ? 'secondary' : 'primary'}
                            sx={{ minWidth: 120, order: i }}
                            onClick={() => handleTypeChange(type)}
                            
                        >
                            {TypeDisplayNames[type as 'cpo'] || type}
                        </Button>
                    ))}
                </Box>

                {/* Required Fields */}
                <Typography variant="h6">Required Fields</Typography>
                <Box
                    maxWidth="50%"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mb: 3,
                    }}
                >
                    {requiredProps.map((propName) => (
                        <DynamicProp
                            key={propName}
                            name={propName}
                            editMode
                            objectType={objectType as ObjectType}
                            onEdit={(value) => setFormData((prev) => ({ ...prev, [propName]: value }))}
                        />
                    ))}
                </Box>

                {/* Other Fields */}
                <Typography variant="h6">Other Fields</Typography>
                <Box
                    maxWidth="50%"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mb: 3,
                    }}
                >
                    {otherProps.map((propName) => (
                        <DynamicProp
                            key={propName}
                            name={propName}
                            editMode
                            onEdit={(value) => setFormData((prev) => ({ ...prev, [propName]: value }))}
                        />
                    ))}
                </Box>

                {/* Submit Button */}
                <Button type="submit" variant="contained">
                    Create {objectType}
                </Button>
            </form>
        </Container>
    );
};

export default CreateObject;
