import * as React from "react";
import Grid from "@mui/material/Grid2";
import Chip from "@mui/material/Chip";
import Copyright from "../../internals/components/Copyright";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import ERP from "../../util/ERP";
import { Object, ObjectType, TypeDisplayNames } from "../../util/Typings";
import { Box, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";
import DynamicProp from "../../util/DynamicProp";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export type Field = {
  name: string;
  display: string;
};

function RenderReferenceCell(
  field: string,
  input?: string,
  objectType?: ObjectType
) {
  if (input) {
    if (field !== "name") {
      return (
        <DynamicProp
          name={field}
          condensed
          value={input}
          objectType={objectType}
        />
      );
    } else return input;
  } else return <>UNKNOWN</>;
}

const QueryDataGrid = (props: {
  objectType: ObjectType;
  fields: Field[];
  filter?: { [key: string]: string };
  cellClicked?: (p: unknown) => void;
  handleRowSelect?: (selection: GridRowSelectionModel) => void;
}) => {
  const [objects, setObjects] = useState<Object[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const resolvedFields = new Map<string, string>();

  const handleSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection);
    onSelectionChange(newSelection);
  };
  const onSelectionChange = (selection: GridRowSelectionModel) => {
    if (props.handleRowSelect) props.handleRowSelect(selection);
  };
  useEffect(() => {
    const fetchData = () => {
      // render main data
      ERP.GetCollection(
        props.objectType,
        props.fields.map((f) => f.name)
      ).then((objs) => {
        setObjects(
          objs.filter((obj) => {
            if (props.filter) {
              for (const filterProp in props.filter) {
                const filterValue = props.filter[filterProp];
                return obj[filterProp as "id"].toLowerCase() === filterValue;
              }
            } else return true;
          })
        );
      });
    };
    fetchData();
  }, [props.filter]);

  let columns: GridColDef[] = [];
  columns = props.fields.map((field) => {
    return {
      field: field.name,
      headerName: field.display,
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) =>
        RenderReferenceCell(field.name, params.value, props.objectType),
    };
  });
  return (
    <DataGrid
      autoHeight
      checkboxSelection
      onRowSelectionModelChange={handleSelectionModelChange}
      onCellClick={props.cellClicked}
      rows={objects}
      columns={columns}
      //add filter here
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 20 } },
      }}
      pageSizeOptions={[10, 20, 50]}
      disableColumnResize
      density="compact"
      slotProps={{
        filterPanel: {
          filterFormProps: {
            logicOperatorInputProps: {
              variant: "outlined",
              size: "small",
            },
            columnInputProps: {
              variant: "outlined",
              size: "small",
              sx: { mt: "auto" },
            },
            operatorInputProps: {
              variant: "outlined",
              size: "small",
              sx: { mt: "auto" },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: "outlined",
                size: "small",
              },
            },
          },
        },
      }}
    />
  );
};

export default function CollectionOverview(props: {
  objectType: ObjectType;
  displayFields: Field[];
  filter?: { [key: string]: string };
  onCellClick?: (p: unknown) => void;
}) {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [filter, setFilter] = useState<{} | undefined>(undefined);

  useEffect(() => {
    const getFilter = () => {
      const FILTER =
        props.objectType === "contact"
          ? { type: window.location.href.split("=")[1] }
          : undefined;
      setFilter(FILTER);
    };

    getFilter();
  }, [window.location.href, window.location.pathname]);

  const handleAddClick = () => {
    // Navigate to another page, you can use react-router for navigation
    if (!ItemsSelected()) {
      navigate(`/create#${props.objectType}`);
    } else {
      if (deleteMode === false) {
        setDeleteMode(true);
      }
    }
  };

  const resolveDelete = (confirmed: boolean) => {
    if (confirmed) {
      ERP.DeleteObjects(props.objectType, selectedItems).then(() => {
        setSelectedItems([]);
      });
    }
    setDeleteMode(false);
  };

  const ItemsSelected = () => {
    return selectedItems.length > 0;
  };

  return (
    <Box
      key={JSON.stringify(props)}
      sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}
    >
      {/* cards */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        sx={{ mb: 2 }}
      >
        <Typography component="h2" variant="h6">
          {`${TypeDisplayNames[props.objectType as "part"]}s`}
        </Typography>
        <Box>
          {ItemsSelected() ? (
            <IconButton onClick={handleAddClick}>
              <DeleteForeverIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleAddClick}>
              <AddIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Grid container spacing={2} columns={10}>
        <Grid size={{ md: 12, lg: 12 }}>
          {deleteMode ? null : (
            <QueryDataGrid
              objectType={props.objectType}
              fields={props.displayFields}
              filter={filter}
              cellClicked={props.onCellClick}
              handleRowSelect={(selection) => {
                setSelectedItems(selection as []);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
      {deleteMode ? (
        <ConfirmationDialog
          message={`are you sure you want to delete ${selectedItems.length} item(s)?`}
          onResolve={resolveDelete}
        />
      ) : null}
    </Box>
  );
}
