import React, { useRef, useEffect, Suspense, useState } from 'react';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { Box3, Vector3, OrthographicCamera } from 'three';
import { CircularProgress } from '@mui/material';
import init, { Occt, Mesh } from 'occt-import-js';
import ERP from '../../util/ERP';

// Function to convert mesh data to OBJ format
function convertToObj(meshes: Mesh[]) {
  let objString = '';
  meshes.forEach((mesh: Mesh) => {
    const positionAttribute = mesh.attributes.position;
    const indexAttribute = mesh.index;

    if (positionAttribute) {
      const positions = positionAttribute.array;
      for (let i = 0; i < positions.length; i += 3) {
        objString += `v ${positions[i]} ${positions[i + 1]} ${positions[i + 2]}\n`;
      }
    }

    if (indexAttribute) {
      const indices = indexAttribute.array;
      for (let i = 0; i < indices.length; i += 3) {
        objString += `f ${indices[i] + 1} ${indices[i + 2] + 1} ${indices[i + 1] + 1}\n`;
      }
    }
  });

  return objString;
}

const ModelWithRelevantEdges: React.FC<{ objUrl: string }> = React.memo(({ objUrl }) => {
  const obj = useLoader(OBJLoader, objUrl);
  const objRef = useRef<THREE.Object3D>(null);

  useEffect(() => {
    if (objRef.current) {
      const box = new THREE.Box3().setFromObject(objRef.current);
      const center = new THREE.Vector3();
      box.getCenter(center);
      objRef.current.position.sub(center);

      // Clear existing children (if any)
      objRef.current.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          // Compute geometry and create relevant edges
          const geometry = child.geometry;
          geometry.computeVertexNormals(); // Ensure normals are computed for lighting

          // Only create edges for the relevant faces of the geometry
          const edges = new THREE.EdgesGeometry(geometry);
          const lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });

          // Create line segments for edges
          const lineSegments = new THREE.LineSegments(edges, lineMaterial);
          
          // Positioning the line segments properly
          lineSegments.position.copy(child.position);
          lineSegments.rotation.copy(child.rotation);
          lineSegments.scale.copy(child.scale);

          // Add line segments to the object
          if (objRef && objRef.current) objRef.current.add(lineSegments);

          // Set material for the mesh
          child.material = new THREE.MeshStandardMaterial({
            color: 0xcccccc,
            side: THREE.DoubleSide,
          });
        }
      });
    }
  }, [obj]);

  return <primitive ref={objRef} object={obj} />;
});

const IsometricCamera: React.FC = () => {
  const { camera, size } = useThree();

  useEffect(() => {
    const aspect = size.width / size.height;
    const frustumSize = 100;
    const zoom = 20;

    (camera as OrthographicCamera).left = (-frustumSize * aspect) / zoom;
    (camera as OrthographicCamera).right = (frustumSize * aspect) / zoom;
    (camera as OrthographicCamera).top = frustumSize / zoom;
    (camera as OrthographicCamera).bottom = -frustumSize / zoom;
    (camera as OrthographicCamera).near = 0.1;
    (camera as OrthographicCamera).far = 10000;

    camera.position.set(50, 50, 50);
    camera.lookAt(0, 0, 0);
    (camera as OrthographicCamera).updateProjectionMatrix();
  }, [camera, size]);

  return null;
};

const ObjViewer: React.FC<{ objUrl: string; title?: string; description?: string; scale?: number; showWireframe?: boolean; }> = ({ objUrl, title, description, scale, showWireframe = false }) => {
  const [objFileUrl, setObjFileUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const convertStepToObj = async () => {
      try {
        await init({ locateFile: (name) => '/occt-import-js.wasm' })
          .then(async (occt) => {
            const { url, config } = ERP.GetFileURL(objUrl);
            const response = await fetch(url, config);
            const stepData = await response.arrayBuffer();
            const fileBuffer = new Uint8Array(stepData);
            const viewParams = {
              linearDeflectionType: 'absolute_value',
            };
            const { success, meshes } = occt.ReadStepFile(fileBuffer, {
              linearUnit: 'centimeter',
              ...viewParams,
            });

            if (success) {
              const objData = convertToObj(meshes);
              const objBlob = new Blob([objData], { type: 'text/plain' });
              const newObjUrl = URL.createObjectURL(objBlob);
              setObjFileUrl(newObjUrl);
            } else {
              console.error('Failed to read STEP file');
            }
          })
          .catch((err) => {
            console.error("ERROR LOADING OCCT. THIS IS USUALLY A WASM LOCATION ERROR WITH THE URL.");
          });
      } catch (error) {
        console.error('Error converting STEP to OBJ:', error);
      } finally {
        setLoading(false);
      }
    };

    convertStepToObj();
  }, [objUrl]);

  return loading ? (
    <CircularProgress />
  ) : (
    <Canvas orthographic>
      <IsometricCamera />
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <Suspense fallback={null}>
        {!loading && objFileUrl && <ModelWithRelevantEdges objUrl={objFileUrl} />}
      </Suspense>
      <OrbitControls minDistance={20} maxDistance={200} />
    </Canvas>
  );
};

export default ObjViewer;
