import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Divider, CircularProgress, Card, Chip } from '@mui/material';
import ERP from '../../util/ERP';
import { Object, ObjectType, WorkCell } from '../../util/Typings';
import DynamicProp from '../../util/DynamicProp';
import DynamicField from './DynamicField';

interface ObjectToolTipProps {
  objectType: ObjectType;
  objectId: string;
  data?: {
    name: string;
    [key: string]: any;
  };
}

// Helper function to capitalize the first letter
const capitalizeFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const ObjectToolTip: React.FC<ObjectToolTipProps> = ({ objectType, objectId, data }) => {
  const [loading, setLoading] = useState(false);
  const [objectData, setObjectData] = useState<any | undefined>(data);
  const boxRef = useRef(null); // Create a ref for the Bo;

  useEffect(() => {
    const GetData = async () => {
        setLoading(true);
        try {
          const obj = await ERP.GetObject(objectType, objectId);
          setObjectData(obj as WorkCell);
        } catch (err) {
          // Handle error if needed
        } finally {
          setLoading(false);
        }
    };

    GetData()
  }, [objectType, objectId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box key={objectId} style={{  width: '100%', padding: '0px'}} ref={boxRef}>
      <Typography
        variant="h6"
        style={{
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 0, // Remove any default margin
            padding: '0px 0', // Adjust padding as needed
            fontSize: '1rem', // Adjust font size to your preference
        }}
      >
        {capitalizeFirst(objectType)}: {objectData?.name}
      </Typography>

      <Divider style={{ marginBottom: '10px' }} />
      {objectData && (<>
            {objectType === "workcell" ? <>
              <Box display="flex" justifyContent="space-between" key={'xyy'} style={{ marginBottom: '5px' }}>
                <Typography variant="body2" fontWeight="bold">
                  WO:
                </Typography>
                <DynamicField fieldName='workorder' value={`workorders/${objectData.wo}`}/> 
              </Box>
              <Box display="flex" justifyContent="space-between" key={'xyx'} style={{ marginBottom: '5px' }}>
                <Typography variant="body2" fontWeight="bold">
                  OP:
                </Typography>
                <Chip label={`#${objectData.opnum}`}/>
              </Box>
              <Box display="flex" justifyContent="space-between" key={'xxy'} style={{ marginBottom: '5px' }}>
                <Typography variant="body2" fontWeight="bold">
                  Qty. Complete:
                </Typography>
                <Typography variant='body2' fontWeight={"normal"}>
                  {objectData.quantity_complete}/{objectData.quantity_total}
                </Typography>
              </Box>
            </> : <>
              {objectData.client && <Box display="flex" justifyContent="space-between" key={'x1'} style={{ marginBottom: '5px' }}>
              <Typography variant="body2" fontWeight="bold">
                  Client:
                </Typography>
                <DynamicField fieldName='client' value={`contacts/${objectData.client}`}/> 
              </Box>}
              {objectData.status && <Box display="flex" justifyContent="space-between" key={'x2'} style={{ marginBottom: '5px' }}>
              <Typography variant="body2" fontWeight="bold">
                  Status:
                </Typography>
                <DynamicField fieldName='status' value={objectData.status}/> 
              </Box>}
              {objectData.description && <Box display="flex" justifyContent="space-between" key={'x3'} style={{ marginBottom: '5px' }}>
                <Typography variant="body2" fontWeight="bold">
                  Desc:
                </Typography>
                <Typography variant="body2">
                  {objectData.description}
                </Typography>
              </Box>}
              {objectData.type && <Box display="flex" justifyContent="space-between" key={'x4'} style={{ marginBottom: '5px' }}>
                <Typography variant="body2" fontWeight="bold">
                  Type:
                </Typography>
                <Typography variant="body2">
                  {objectData.type}
                </Typography>
              </Box>}
            </>}
      </>)}
    </Box>
  );
};

export default ObjectToolTip;
