import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "../../internals/components/Copyright";
import { useState, useEffect } from "react";
import ERP from "../../util/ERP";
import {
  ObjectType,
  Part,
  Object,
  Operation,
  PrintEnabledDocs,
  RFQ,
  WorkOrder,
  TypeDisplayNames,
  DocsWithLineItems,
} from "../../util/Typings";
import { useLocation, useNavigate } from "react-router-dom";
import ObjViewer from "../3D/ObjViewer";
import { Button, Card, Stack } from "@mui/material";
import DynamicTable from "../Core/DynamicTable";
import DynamicProp from "../../util/DynamicProp";
import { notificationManager } from "../../managers/NotificationManager";
import EditTools from "../Core/EditTools";
import PartOperations from "../Core/PartOperations";
import FileList from "../Core/FileList";
import LineItems from "../Core/LineItems";
import TimeClockTable from "../Timeclock/TimeClockTable";
import PrintButton from "../Core/PrintButton";
import ObjectOverview from "./ObjectOverview";
import WorkOrderOperations from "../Core/WorkOrderOperations";
import BOM from "../Core/BOM";
import InventorySummary from "../Core/InventorySummary";

function FormatPropName(propName: string) {
  return propName
    .charAt(0)
    .toUpperCase()
    .concat(propName.substring(1, propName.length));
}

const excludedProps = ["operations", "items", "bom", "quantity_inventory"];

const ObjectViewer = (componentProps: { objectType: ObjectType }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // add part viewer based on current path
  const path = location.pathname.split("/");
  const objID = path[path.length - 1];

  const [data, setData] = useState<Object | RFQ | WorkOrder>({
    name: `...`,
    id: `...`,
    items: [],
  });
  const [files, setFiles] = useState<{ name: string }[]>([]);
  const [isEditMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState<{ name: string; value: string }[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await ERP.GetObject(componentProps.objectType, objID)
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          navigate("/error");
        });

      await ERP.GetObjectFiles(componentProps.objectType, objID)
        .then((data) => {
          console.log(data);
          setFiles(data);
        })
        .catch((err) => {
          console.log("NO DATA FOR PART: ", err);
        });
    };

    fetchData();
  }, [objID]);

  const props = [];
  for (let p in data) {
    props.push(p);
  }

  const handlePropEdit = (propName: string, value: string | number) => {
    console.log(propName, value);
    setData((prevData) => ({
      ...prevData,
      [propName]: value,
    }));
    let c = changes;
    c = c.filter((p) => p.name !== propName);
    c.push({
      name: propName,
      value: value as string,
    });
    setChanges(c);
  };

  const handleSave = () => {
    setEditMode(false);
    // process changes
    // clear changes
    if (changes.length > 0) {
      setChanges([]);
      ERP.UpdateObject(componentProps.objectType, objID, changes)
        .then(() => {
          notificationManager.notify(
            "success",
            `Changes saved. (${changes.length})`
          );
        })
        .catch((err) => {
          notificationManager.notify("error", `Changes failed to save: ${err}`);
        });
    } else notificationManager.notify("warning", "No changes to save.");
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography component="h1" variant="h4" sx={{ mb: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <span>
              {TypeDisplayNames[componentProps.objectType as "part"] ||
                componentProps.objectType}{" "}
              Overview:{" "}
              <u>
                <i>{data.name}</i>
              </u>
            </span>
            {PrintEnabledDocs.includes(componentProps.objectType) && (
              <PrintButton
                objectType={componentProps.objectType}
                objectId={objID}
              />
            )}
          </Stack>
        </Typography>
        <EditTools
          isEditing={isEditMode}
          toggleEdit={(editing) => setEditMode(editing)}
          onSave={handleSave}
        />
      </Stack>
      <Grid container spacing={2} justifyContent="left">
        {props
          .sort((a, b) => {
            if (a === "name") return -1;
            if (b === "name") return 1;
            return a.localeCompare(b);
          })
          .filter((p) => !excludedProps.includes(p))
          .map((prop, i) => (
            <Grid>
              <DynamicProp
                key={`${i}`}
                objectType={componentProps.objectType}
                name={prop}
                value={data[prop as "id"] as string}
                editMode={isEditMode}
                onEdit={(value) => {
                  handlePropEdit(prop, value as string);
                }}
              />
            </Grid>
          ))}
      </Grid>
      <br />
      {DocsWithLineItems.includes(componentProps.objectType) && (
        <LineItems
          objectType={componentProps.objectType}
          objectId={objID}
          propData={(data as RFQ).items}
        />
      )}
      {componentProps.objectType === "part" && (
        <PartOperations partId={objID} />
      )}
      {componentProps.objectType === "workorder" && (
        <WorkOrderOperations
          partId={(data as WorkOrder).part}
          workOrder={objID}
        />
      )}
      {componentProps.objectType === "part" && <BOM partId={objID} />}
      {(componentProps.objectType === "part" ||
        componentProps.objectType === "cot") && (
        <InventorySummary
          objectType={componentProps.objectType}
          objectId={objID}
        />
      )}
      <br />
      {files.length > 0 && (
        <Card>
          <Typography component="h1" variant="h4" sx={{ mb: 2 }}>
            Files
          </Typography>
          <FileList
            dir={`${componentProps.objectType}/${objID}/`}
            files={files.map((f) => {
              return {
                name: f.name,
                url: ERP.GetFileURL(
                  `${componentProps.objectType}/${objID}/${f.name}`
                ).url,
              };
            })}
          />
        </Card>
      )}
      <br />
      {componentProps.objectType === "part" ? (
        <Box
          sx={{ padding: "20px", textAlign: "left", justifyContent: "left" }}
        >
          {" "}
          {/* Align content to the left */}
        </Box>
      ) : undefined}
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
};
export default ObjectViewer;
