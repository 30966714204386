import { initializeApp } from "firebase/app";
import {
  initializeAuth,
  getAuth,
  setPersistence,
  browserSessionPersistence, // Use sessionStorage instead of localStorage
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCK1MQbdgtlI881PwJrqzhikzhMG50N03E",
  authDomain: "kam-erp.firebaseapp.com",
  projectId: "kam-erp",
  storageBucket: "kam-erp.appspot.com",
  messagingSenderId: "878211173523",
  appId: "1:878211173523:web:390e595926e07466399166",
  measurementId: "G-GHFY3T25VY",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Change persistence to sessionStorage
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // Authentication persistence is now tied to the session
  })
  .catch((err) => {
    throw err;
  });

// Initialize Firebase
const firebase = {
  app: app,
  auth: auth,
};

export default firebase;
