// components/NotificationSystem.tsx
import React, { useEffect, useState } from 'react';
import { Snackbar, Alert, Slide, SlideProps } from '@mui/material';
import { notificationManager, NotificationEvent } from '../../managers/NotificationManager'; // Import manager and types

interface Notification extends NotificationEvent {
  open: boolean; // Extend the event with an 'open' state for local management
}

const SlideTransition = (props: SlideProps) => (
  <Slide {...props} direction="down" />
);

const NotificationSystem: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Subscribe to new notifications
  useEffect(() => {
    const handleNewNotification = (notification: NotificationEvent) => {
      const newNotification: Notification = { ...notification, open: true }; // Add 'open' state
      setNotifications((prev) => [...prev, newNotification]); // Stack new notifications
    };

    notificationManager.subscribe(handleNewNotification);

    return () => {
      notificationManager.unsubscribe(handleNewNotification); // Cleanup on unmount
    };
  }, []);

  // Handle closing a notification with animation
  const handleClose = (id: string) => {
    setNotifications((prev) =>
      prev.map((n) => (n.id === id ? { ...n, open: false } : n)) // Mark as closed
    );

    // Remove notification after animation completes
    setTimeout(() => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, 300); // 300ms to match the Slide transition duration
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 9999,
      }}
    >
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={notification.open}
          onClose={() => handleClose(notification.id)}
          autoHideDuration={3000}
          TransitionComponent={SlideTransition}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => handleClose(notification.id)}
            severity={notification.type}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
};

export default NotificationSystem;
