import * as React from 'react';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-charts/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-tree-view/themeAugmentation';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from '../components/AppNavbar';
import Header from '../components/Header';
import { useState } from "react"
import SideMenu from '../components/SideMenu';
import PartOperations from "../components/Core/PartOperations";

import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from '../theme/customizations';
import AppTheme from '../shared-theme/AppTheme';
import DynamicProp from '../util/DynamicProp';
import { Button, Card } from '@mui/material';
import DriveAccess from './DriveAccess';
import { Object3D } from 'three';
import ObjViewer from '../components/3D/ObjViewer';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Debug(props: { disableCustomTheme?: boolean }) {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box 
          component="main"
          sx={(theme) => ({
            flexGrow: 1, 
            backgroundColor: theme.palette.background.default
              ? `rgba(${theme.palette.background.default} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header /> 
            <h1>SYSTEM DEBUG MENU</h1>
            <div>
              <Button variant="contained" color="primary" onClick={toggleEdit}>
                {isEditing ? 'Stop Editing' : 'Start Editing'}
              </Button>
              <p>Editing state: {isEditing ? 'True' : 'False'}</p>
            </div>
            <Card>
              <ObjViewer objUrl='http://localhost:3050/models/TEST.STEP'/>
            </Card>
            <DynamicProp name='Part'  editMode={isEditing} />
            <DynamicProp name='Customer'  editMode={isEditing} />
            <DynamicProp name='Resource'  editMode={isEditing} />
            <DynamicProp name='Notes'  editMode={isEditing}/>
            <DynamicProp name='Due Date'  editMode={isEditing}/>
            <PartOperations partId='cLACI3cuMWldIQaBZw9L'/>
            <h1>END</h1>
            <DriveAccess />
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
