import * as React from "react";
import { styled } from "@mui/material/styles";
import Divider, { dividerClasses } from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import { paperClasses } from "@mui/material/Paper";
import { listClasses } from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon, { listItemIconClasses } from "@mui/material/ListItemIcon";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import MenuButton from "./MenuButton";
import { signOut } from "firebase/auth";
import FB from "../util/Firebase";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Chip } from "@mui/material";
import { useUser } from "./Core/UserContext";
import ERP from "../util/ERP";
import { notificationManager } from "../managers/NotificationManager";
import { Navigate, useNavigate } from "react-router-dom";

const MenuItem = styled(MuiMenuItem)({
  margin: "2px 0",
});

export default function OptionsMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    await signOut(FB.auth);
  };

  const { user, setUser } = useUser();

  const toggleClockStatus = () => {
    const clockedIn = user.clockStatus;
    if (clockedIn) {
      ERP.LocalUser.ClockOut().then((time) => {
        notificationManager.notify("success", `Clocked out @ ${time}`);
      });
    } else {
      ERP.LocalUser.ClockIn().then((time) => {
        notificationManager.notify("success", `Clocked in @ ${time}`);
      });
    }

    setUser((prevUser) => ({
      ...prevUser,
      clockStatus: !prevUser.clockStatus,
    }));
  };

  const clockedIn = user.clockStatus;
  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: "transparent" }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: "4px",
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: "4px -4px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            window.location.href = `/objects/users/${ERP.LocalUser.ID()}`;
          }}
        >
          My account
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            toggleClockStatus();
          }}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: "auto",
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Clock {clockedIn ? "Out" : "In"}</ListItemText>
          <ListItemIcon>
            {clockedIn ? (
              <ArrowOutwardIcon fontSize="small" />
            ) : (
              <AddAlarmIcon fontSize="small" />
            )}
          </ListItemIcon>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleLogout}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: "auto",
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Logout</ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
