import { Chip, CircularProgress, Typography } from "@mui/material";
import ERP from "../../util/ERP";
import { useEffect, useState } from "react";

export type DynamicFieldProps = { fieldName: string, value: string }

const DynamicFields = ["res"]

export default function DynamicField(props: DynamicFieldProps) {
    const isDynamic = props.value.includes("/")
    const [resolved, setResolved] = useState(false);
    const [resolvedText, setResolvedText] = useState('');
    useEffect(() => {
        const resolve = () => {
            const objectLocationData = props.value.split("/");
            const resource = objectLocationData[0].substring(0, objectLocationData[0].length-1);
            const id = objectLocationData[1]
            ERP.ResolveDynamicProp(resource as "part", id).then((val) => {
                setResolvedText(val);
            }).catch((err) => {
                setResolvedText('');
            }).finally(() => {
                setResolved(true);
            })
        };

        if (isDynamic) resolve();
    })
    if (isDynamic) {
        const resolveSuccess = (resolvedText !== '');
        return resolved ? <Chip label={resolveSuccess ? resolvedText : props.value} color={resolveSuccess ? "secondary" : "error"} size="small" /> : <CircularProgress size={10} />
    } else {
        return <>{props.value}</> 
    }
}