import React, { useState } from "react";
import { Box, Button, TextField, MenuItem, Typography } from "@mui/material";
import { notificationManager } from "../../managers/NotificationManager";
import DynamicProp from "../../util/DynamicProp";
import ERP from "../../util/ERP";
import { sendPasswordResetEmail } from "firebase/auth";
import Auth from "../../util/Auth";
import firebase from "../../util/Firebase";

const UserCreateWizard = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userPerms, setUserPerms] = useState("");

  const userRoles = ["Admin", "Editor", "Viewer"]; // Replace with your roles

  const handleCreate = async () => {
    const userData = { email, firstName, lastName, userRole, userPerms };
    console.log(userData);
    const success = true; // Replace with your API call logic
    ERP.Users.Create(userData)
      .then((packet) => {
        notificationManager.notify("success", "User created successfully!");
        setOpen(false);
        setEmail("");
        setFirstName("");
        setLastName("");
        setUserRole("");
        const { link } = packet;
        sendPasswordResetEmail(firebase.auth, userData.email);
      })
      .catch((err) => {
        notificationManager.notify(
          "error",
          (err as string) || "An error occurred."
        );
      });
  };

  return (
    <Box sx={{ justifyItems: "left" }}>
      <Button variant="contained" onClick={() => setOpen(!open)}>
        {!open ? "Create User" : "Cancel"}
      </Button>
      {open && (
        <Box
          sx={{
            mt: 2,
            p: 2,
            border: "1px solid #ccc",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            justifyItems: "left",
            gap: 2,
            maxWidth: 300,
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: -1 }}>
            <b>User Info</b>
          </Typography>
          <TextField
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            size="small"
          />
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            size="small"
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            size="small"
          />
          <Typography variant="subtitle1" sx={{ mb: -3 }}>
            <b>User Role</b>
          </Typography>
          <DynamicProp
            onEdit={(value) => {
              setUserRole(value as string);
            }}
            name="role"
            value={userRole}
            condensed
            editMode
          />
          <Typography variant="subtitle1" sx={{ mb: -3 }}>
            <b>User Permissions</b>
          </Typography>
          <DynamicProp
            onEdit={(value) => {
              setUserPerms(value as string);
            }}
            name="permission_level"
            value={userPerms}
            condensed
            editMode
          />
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UserCreateWizard;
