import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-charts/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-tree-view/themeAugmentation';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from '../components/AppNavbar';
import Header from '../components/Header';
import { useEffect, useState } from "react";
import SideMenu from '../components/SideMenu';
import { useLocation } from 'react-router-dom';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from '../theme/customizations';
import AppTheme from '../shared-theme/AppTheme';
import ResourcesOverview from '../components/Resources/ResourceOverview';
import ObjectOverview from '../components/Objects/ObjectOverview';
import ObjectViewer from '../components/Objects/ObjectViewer';
import { ObjectType, WorkCell } from '../util/Typings';
import GanttChart from '../components/Core/GanttChart';
import { Card, Typography } from '@mui/material';
import EditTools from '../components/Core/EditTools';
import ERP from '../util/ERP';
import PropEditor from '../util/PropEditor';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Schedule(props: { disableCustomTheme?: boolean }) { 
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [editHistory] = useState(new PropEditor((changes) => {
    changes.forEach((obj, _) => {
      const object = obj as { id: string, title: string, start: Date, end: Date, group: string };
      ERP.UpdateObject('workcell', object.id, [
        { name: 'start', value: object.start.toISOString()}, 
        { name: 'end', value: object.end.toISOString()},
        { name: 'group', value: object.group }
      ]).then((update) => {
        console.log("SAVED.")
      })
    })
    return true;
  }));
  // add part viewer based on current path
  const path = location.pathname.split("/");
  const [data, setData] = useState<{ id: string, title: string, start: Date, end: Date, dueDate: Date, group: string, wo: string}[]>([]);
  useEffect(() => {
    const update = () => {
      ERP.GetCollection<WorkCell>("workcell", ['wo', 'group', 'opnum', 'start', 'end', 'quantity_complete', 'quantity_total']).then((cells) => {
        setData(
          cells.map((cell, i) => ({
            id: cell.id,
            wo: cell.wo,
            title: `OP#${cell.opnum} - ${Number(cell.quantity_complete)/Number(cell.quantity_total)*100}%`,
            start: new Date(cell.start),
            end: new Date(cell.end),
            dueDate: new Date("2024-12-01"),
            group: cell.group
          })
        ));
      });
    }

    update();
  }, [])

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box 
          component="main"
          sx={(theme) => ({
            flexGrow: 1, 
            backgroundColor: theme.palette.background.default
              ? `rgba(${theme.palette.background.default} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Card style={{ width: '100%', flexDirection: "column", display: 'flex' }}> 
                <Box style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: "space-between"}}>
                <Typography variant='h4'>Machines</Typography>
                <EditTools isEditing={isEditing} toggleEdit={setIsEditing} onSave={() => {
                  setIsEditing(false);
                  editHistory.Save();
                }}/>
                </Box>
                <GanttChart isEditing={isEditing} onEdit={(item) => {
                  const newData = [...data];
                  const newItem = newData[item.id as number];
                  newItem.group = item.group ? item.group.toString() : newItem.group;
                  newItem.start = new Date(item.start?.valueOf() as unknown as Date) || newItem.start;
                  newItem.end = new Date(item.end?.valueOf() as unknown as Date) || newItem.end;
                  editHistory.TrackChange(newItem.id as unknown as string, newItem);
                  setData(newData);
                }} data={data} />
            </Card>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
