import React, { useEffect, useRef, useState } from 'react';
import { Timeline, DataSet, DataItem, TimelineOptions, TimelineItem } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import './GanttChartStyles.css'; // Custom styles
import { Card } from '@mui/material';
import ObjectToolTip from './ObjectToolTip';

type TaskData = {
  id: string;
  title: string; // Format: "{wo}/{opnum}"
  wo: string;
  start: Date;
  end: Date;
  dueDate: Date;
  group: string;
};

type GanttChartProps = {
  data: TaskData[];
  isEditing: boolean;
  onEdit?: (item: TimelineItem) => void;
};

const GanttChart: React.FC<GanttChartProps> = ({ data, onEdit, isEditing }) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const timelineInstance = useRef<Timeline | null>(null);
  const items = useRef<DataSet<DataItem>>(new DataSet<DataItem>());
  const groupsRef = useRef<DataSet<{ id: string; content: string }>>(new DataSet());
  const colorMap = useRef<Map<string, string>>(new Map());
  const [tooltipContent, setTooltipContent] = useState<string>('');
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  // Assign a unique color for each work order ID
  const getOrAssignColor = (workOrderId: string): string => {
    const hash = Array.from(workOrderId).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const randomHue = hash % 360; // Hash the workOrderId and mod by 360 for the hue value
    const color = `hsl(${randomHue}, 50%, 50%)`; // Generate color based on the hash
    return color;
  }; 

  useEffect(() => {
    if (!timelineRef.current) return;

    const initialGroups = new DataSet([
      { id: 'L1', content: 'L1' },
      { id: 'L2', content: 'L2' },
      { id: 'M1', content: 'M1' },
      { id: 'M2', content: 'M2' },
      { id: 'M3', content: 'M3' },
      { id: 'M4', content: 'M4' },
      { id: 'M5', content: 'M5' },
    ]);

    groupsRef.current = initialGroups;

    const options: TimelineOptions = {
      editable: {
        add: false,
        remove: false,
        updateTime: isEditing,
        updateGroup: isEditing,
      },
      stack: true,
      orientation: 'top',
      groupOrder: 'id',
      start: new Date(new Date().setDate(new Date().getDate() - 1)),
      end: new Date(new Date().setDate(new Date().getDate() + 2)),
      onMove: (item, callback) => {
        if (onEdit) onEdit(item);
        callback(item);
      },
    };

    const timeline = new Timeline(timelineRef.current, items.current, initialGroups, options);

    timeline.on('itemover', (properties) => {
      const item = items.current.get(properties.item);
      if (item) {
        const task = data[properties.item];
        if (task && task.id) {
          setTooltipContent(task.id);
          setTooltipPosition({ top: properties.event.clientY, left: properties.event.clientX });
          setToolTipVisible(true);
        } else console.log("DATA SIZE: ", data.length)
      }
    });

    timeline.on('itemout', () => {
      setTooltipPosition(null);
      setToolTipVisible(false);
    });

    const handleMouseMove = (event: MouseEvent) => {
      if (tooltipRef.current) {
        tooltipRef.current.style.top = `${event.clientY + 10}px`;
        tooltipRef.current.style.left = `${event.clientX + 10}px`;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    timelineInstance.current = timeline;

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      timelineInstance.current?.destroy();
      timelineInstance.current = null;
    };
  }, [data]);

  useEffect(() => {
    const groups = groupsRef.current;
    if (!groups) return;

    if (isEditing) {
      groups.add([
        { id: 'ZGM', content: 'Generic Mill' },
        { id: 'ZGL', content: 'Generic Lathe' },
      ]);
    } else {
      groups.remove(['ZGM', 'ZGL']);
    }
  }, [isEditing]);

  useEffect(() => {
    if (!timelineInstance.current) return;

    items.current.clear();

    const taskItems: DataItem[] = data.map((task, i) => {
      const workOrderId = task.wo; // Extract the work order ID
      const color = getOrAssignColor(workOrderId); // Assign or get a unique color

      return {
        id: i,
        content: task.title,
        start: task.start,
        end: task.end,
        group: task.group,
        style: `background-color: ${color}; border: 2px solid ${color}; color: #fff;`,
        editable: {
          updateTime: isEditing,
          updateGroup: isEditing,
        },
      };
    });

    items.current.add(taskItems);
  }, [data, isEditing]);

  return data ? (<div style={{ position: 'relative' }}>
    <div ref={timelineRef} style={{ width: '100%' }} />
    {/* Tooltip */}
    {toolTipVisible && (
      <div
        style={{
          position: 'fixed',
          top: tooltipPosition?.top ?? 0,
          left: tooltipPosition?.left ?? 0,
          pointerEvents: 'none',
          zIndex: 1000,
          transition: 'opacity 0.2s ease',
        }}
        ref={tooltipRef}
      >
        {!isEditing && <Card>
          <ObjectToolTip objectType="workcell" objectId={tooltipContent} />
        </Card>}
      </div>
    )}
  </div>) : (<>NO DATA</>);
};

export default GanttChart;
