import React from 'react';
import { IconButton, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from 'react-router-dom';

interface PrintButtonProps {
    objectType: string;
    objectId: string;
}

const PrintButton: React.FC<PrintButtonProps> = ({ objectType, objectId }) => {
    const navigate = useNavigate();

    const handlePrint = () => {
        const url = `/print?type=${objectType}&id=${objectId}`;
        navigate(url);
    };

    return (
        <IconButton onClick={handlePrint} color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
            <PrintIcon />
        </IconButton>
    );
};

export default PrintButton;
