import { signInWithEmailAndPassword } from "firebase/auth";
import FB from "./Firebase"

const Auth = {
    LogIn: async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(FB.auth, email, password);
            // Handle successful login (e.g., redirect or update state)
            
          } catch (error) {
            console.error("Error logging in:", error);
            throw error; // Optionally re-throw the error for further handling
          }
    }
};

export default Auth