import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog(props: {message: string, onResolve: (confirmed: boolean) => void}) { 

  const handleConfirm = (confirm: boolean) => {
    return () => {
        props.onResolve(confirm);
    }
  }

  return (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Objects"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm(false)}>Cancel</Button>
          <Button  onClick={handleConfirm(true)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  );
}
