import { Chip, Box, Card, CardContent, CircularProgress } from "@mui/material";
import { Typography, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Divider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom"
import ERP from "../util/ERP";
import {Contact, RFQ as RFQType, Packingslip,  Part} from "../util/Typings";
import { notificationManager } from "../managers/NotificationManager";
import AppTheme from '../shared-theme/AppTheme';
import {
    chartsCustomizations,
    dataGridCustomizations,
    datePickersCustomizations,
    treeViewCustomizations,
  } from '../theme/customizations'
import DynamicProp from "../util/DynamicProp";
const xThemeComponents = {
    ...chartsCustomizations,
    ...dataGridCustomizations,
    ...datePickersCustomizations,
    ...treeViewCustomizations,
  };

  interface QuoteData {
    taxExempt: boolean,
    date: string;
    id: string;
    company: string;
    preparedBy: string;
    contact: string;
    phone: string;
    email: string;
    project: string;
    items: { qty: number, name: string, unitCost: number}[]
}


interface PackingSlipData {
    id: string,
    shipAddress: string,
    shipCompany: string
    billAddress: string,
    billCompany: string,
    redact_shipper: boolean,
    items: { qty: number, name: string, qty_ordered: number }[]
}

function FormatNumber(n: number) {
    return (Math.round(n*100)/100).toLocaleString();
}

function validateType(type?: string | null) {
    if (type === null) return false;
    switch(type) {
        case "rfq":
            return true;
            break;
        case "packingslip":
            return true;
            break;
    }
    return false;
}

function PartName(props: { id: string}) {
    const [name, setName] = useState(props.id);

    useEffect(() => {
        const getData = async () => {
            ERP.GetObject("part", props.id).then((part) => {
                setName(part.name);
            }).catch((err) => {
                console.log(err);
            })
        };

        getData();
    }, [props.id])

    if (name === props.id) {
        return <CircularProgress sx={{ maxHeight: '20px', maxWidth: '20px'}}/>
    } else return <>
    {name}</>
}

function PartUnitCost(props: { id: string, mul?: number, callback?: (n: number) => void }) {
    const [cost, setCost] = useState(props.id);
    useEffect(() => {
        const getData = async () => {
            ERP.GetObject<Part>("part", props.id).then((part) => {
                const c = part.pricePer * (props.mul ? props.mul : 1)
                setCost(FormatNumber(c));
                if (props.callback) props.callback(c);
            }).catch((err) => {
                console.log(err);
            })
        };

        getData();
    }, [props.id])
    if (cost === props.id) {
        return <CircularProgress sx={{ maxHeight: '20px', maxWidth: '20px'}}/>
    } else return <>
    {cost}</>
}

function RFQ(props: { id: string }) {
    const [quoteData, setQuoteData] = useState<QuoteData>({
        taxExempt: false,
        id: '...',
        date: '9/10/2024',
        company: 'Aircraft Tubular Components, Inc.',
        preparedBy: 'unknown',
        contact: 'Kim',
        phone: '3217579020',
        email: 'rodneys@airtube.net',
        project: '',
        items: [],
    });
    const [subTotal, setSubTotal] = useState<{ [key: number]: number }>({});
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const obj = await ERP.GetObject<RFQType>("rfq", props.id);
                const client = obj.client;
                setQuoteData((prevData) => ({
                    ...prevData,
                    date: new Date(obj.date).toLocaleDateString(),
                    id: obj.name
                }));
                ERP.GetObject<Contact>("contact", client).then((c) => {
                    setQuoteData((prevData) => ({
                        ...prevData,
                        company: c.name,
                        contact: c.contact,
                        phone: c.phone,
                        email: c.email,
                        taxExempt: (c.tax_exempt !== undefined ? c.tax_exempt : false)
                    }));
                }).catch((err) => {
                    console.log("FETCH ERR: ", err)
                });
                setQuoteData((prevData) => ({
                    ...prevData,
                    items: obj.items.map((item) => {
                        return { qty: item['quantity'], name: item['part'], unitCost: 0 };
                    })
                }))
            } catch (err) {
               throw err;
            }
        };
    
        if (props.id) {
            fetchData();
        }
    }, [props.id]);

    // sub total calculation
    let subTotalCalculated = 0;
    for (const key in subTotal) {
        const value = subTotal[key];
        subTotalCalculated+=value;
    }
    
    const taxRate = quoteData.taxExempt ? 0 : 0.07

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3, gap: 3 }}>
            
            {/* Quote Details Card */}
            <Card sx={{ width: '100%', maxWidth: '800px' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>Quote {quoteData.id}</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                        <img src={`${process.env.PUBLIC_URL}/company_logo.png`} alt="Company Logo" height={"100px"} />
                        <Typography>601 Townsend Rd, Cocoa, FL 32926</Typography>
                        <Typography>
                        <a href="https://www.kam-tec.com" target="_blank" rel="noopener noreferrer">
                            www.kam-tec.com
                        </a>
                        </Typography>
                    </Box>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Card>
                                        <Typography variant='h6'>Quoted By</Typography>
                                        <Chip color="primary" label="KAM TECHNOLOGIES, LLC."/>
                                        <Typography>Date: {quoteData.date}</Typography>
                                        <Typography>Prepared by: {quoteData.preparedBy}</Typography>
                                        <Typography>FOB: KAM Technologies, LLC</Typography>
                                    </Card>
                                </TableCell>
                                <TableCell>
                                    <Card>
                                        <Typography variant='h6'>Client</Typography>
                                        <Chip color="primary" label={quoteData.company}/>
                                        <Typography>Contact: {quoteData.contact}</Typography>
                                        <Typography>Phone: {quoteData.phone}</Typography>
                                        <Typography>Email: {quoteData.email}</Typography>
                                    </Card>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            {/* Quote Items Card */}
            <Card sx={{ width: '100%', maxWidth: '800px', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom fontWeight="bold">Quote Items</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow> {/* Light background for header */}
                                    <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Quantity</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Part</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold', color: '#333' }}>Unit Price</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold', color: '#333' }}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <Divider sx={{ minWidth: "400%"}}/> 
                            <TableBody>
                                {quoteData.items.map((item, i) => (<TableRow>
                                    <TableCell>{item.qty}</TableCell>
                                    <TableCell><PartName id={item.name}/></TableCell>
                                    <TableCell align="right">$<PartUnitCost id={item.name}/></TableCell>
                                    <TableCell align="right">$<PartUnitCost id={item.name} mul={item.qty} callback={(cost) => {
                                        setSubTotal((currentTotal) => {
                                            return { ...currentTotal, [i]: cost}
                                        })
                                    }}/></TableCell>
                                </TableRow>))}
                                <Divider sx={{ minWidth: "400%"}}/> 
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>Subtotal</TableCell>
                                    <TableCell align="right">${FormatNumber(subTotalCalculated)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>Sales Tax ({FormatNumber(taxRate*100)}%)</TableCell>
                                    <TableCell align="right">${FormatNumber(taxRate*subTotalCalculated)}</TableCell>
                                </TableRow>
                                <Divider sx={{ minWidth: "400%"}}/> 
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right" sx={{ fontWeight: 'bold', color: '#2e7d32' }}>Total</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold', color: '#2e7d32' }}>${FormatNumber(subTotalCalculated * (1+taxRate))}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            {/* Terms & Conditions Card */}
            <Card sx={{ width: '100%', maxWidth: '800px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Terms & Conditions</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="body2">
                        * Price Includes Material and Machining<br />
                        * Prices are good for 30 days from the date quoted, subject to market conditions...<br />
                        * Delivery quoted is valid at the time of quote. Check for new delivery if PO is delayed.<br />
                        * Above prices apply to the specified lot shipment quantity.<br />
                        * Parts are quoted complete per print or RFQ instructions unless otherwise specified.<br />
                        * Price and delivery depend on receipt of legible drawings, parts lists, and solid models at order placement.<br />
                        * Email sales@kam-tec.com for order placement.
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}

function PackingSlip(props: { id: string }) {
    const [quoteData, setQuoteData] = useState<PackingSlipData>({
        id: '',
        shipAddress: '',
        shipCompany: '',
        billAddress: 'Same unless specified.',
        billCompany: 'Same unless specified.',
        redact_shipper: false,
        items: [],
    });
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const obj = await ERP.GetObject<Packingslip>("packingslip", props.id);
                const po = obj.cpo;

                setQuoteData((prevData) => ({
                    ...prevData,
                    id: obj.name,
                    billAddress: obj.ship_to_company,
                    billCompany: obj.bill_to_company,
                    redact_shipper: obj.redact_shipper
                }));
                ERP.GetObject<Contact>("contact", obj.client).then((c) => {
                    setQuoteData((prevData) => ({
                        ...prevData,
                        shipAddress: c.address,
                        shipCompany: c.name,
                    }));
                }).catch((err) => {
                    console.log("FETCH ERR: ", err)
                });
                setQuoteData((prevData) => ({
                    ...prevData,
                    items: obj.items.map((item) => {
                        return { qty: item['quantity_shipped'], name: item['part'], qty_ordered: item['quantity'] };
                    })
                }))
            } catch (err) {
               throw err;
            }
        };
    
        if (props.id) {
            fetchData();
        }
    }, [props.id]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3, gap: 3 }}>
            
            {/* Quote Details Card */}
            <Card sx={{ width: '100%', maxWidth: '800px' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>Packing Slip {quoteData.id}</Typography>
                    <Divider sx={{ mb: 2 }} />
                    {quoteData.redact_shipper === false && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                        <img src={`${process.env.PUBLIC_URL}/company_logo.png`} alt="Company Logo" height={"100px"} />
                        <Typography>601 Townsend Rd, Cocoa, FL 32926</Typography>
                        <Typography>
                        <a href="https://www.kam-tec.com" target="_blank" rel="noopener noreferrer">
                            www.kam-tec.com
                        </a>
                        </Typography>
                    </Box>}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Card>
                                        <Typography variant='h5'>Ship To</Typography>
                                        <DynamicProp name="contact" condensed value={quoteData.shipCompany}/> 
                                        <Typography variant='h6'>Address</Typography>
                                        <Typography>{quoteData.shipAddress}</Typography>
                                    </Card>
                                </TableCell>
                                <TableCell>
                                    <Card>
                                        <Typography variant='h5'>Bill To</Typography>
                                        <DynamicProp name="contact" condensed value={quoteData.billCompany}/>
                                        <Typography variant='h6'>Address</Typography>
                                        <Typography>{quoteData.billAddress}</Typography>
                                    </Card>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            {/* Quote Items Card */}
            <Card sx={{ width: '100%', maxWidth: '800px', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom fontWeight="bold">Items</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow> {/* Light background for header */}
                                    <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Quantity Shipped</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Quantity Ordered</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>Part</TableCell>
                                </TableRow>
                            </TableHead>
                            <Divider sx={{ minWidth: "200%"}}/>
                            <TableBody>
                                {quoteData.items.map((item, i) => (<TableRow>
                                    <TableCell>{item.qty}</TableCell>
                                    <TableCell>{item.qty_ordered}</TableCell>
                                    <TableCell><PartName id={item.name}/></TableCell>
                                </TableRow>))} 
                                <TableRow>
                                    <TableCell colSpan={2} />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
}

const Printer = (props: {disableCustomTheme?: boolean}) => {
    const url = useLocation().pathname;
    const [searchParams] = useSearchParams();
    const objectType = searchParams.get("type");
    const id = searchParams.get("id");

    if (validateType(objectType)) {
        if (id) {
            return <AppTheme {...props} themeComponents={xThemeComponents}>
                <CssBaseline enableColorScheme />
                {objectType === "rfq" && <RFQ id={id}/>}
                {objectType === "packingslip" && <PackingSlip id={id}/>}
            </AppTheme>
        } else return <Chip color="error" label={"Invalid/No Id provided."}/>
    } else {
        return <Chip color="error" label={"Invalid object type provided."}/>
    }
}

export default Printer