import React, { useState } from 'react';
import { Box, Typography, IconButton, Grid, Card, CardContent, Dialog, DialogContent, AppBar, Toolbar } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import FolderIcon from '@mui/icons-material/Folder';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import CloseIcon from '@mui/icons-material/Close';
import SolidWorksPNG from "./SLDPRT.png";
import ObjViewer from '../3D/ObjViewer';

interface File {
  name: string;
  url: string;
}

interface FileListProps {
  files: File[];
  dir: string;
}

const PdfViewer = ({ pdfUrl }: { pdfUrl: string }) => (
  <iframe
    src={pdfUrl}
    style={{ width: '100%', height: '100%', border: 'none' }}
    title="PDF Viewer"
  />
);

export default function FileList({ files, dir }: FileListProps) {
  const [dense, setDense] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  function getFileIcon(fileName: string) {
    const ext = fileName.split(".").pop()?.toLowerCase();
    const iconSize = dense ? 40 : 64;

    switch (ext) {
      case "pdf":
        return <PictureAsPdfIcon fontSize={dense ? "medium" : "large"} />;
      case "step":
        return (
          <Box sx={{ width: iconSize, height: iconSize }}>
            <ObjViewer objUrl={dir + fileName} />
          </Box>
        );
      case "ods":
        return <TableChartIcon fontSize={dense ? "medium" : "large"} />;
      case "sldprt":
        return (
          <Box
            component="img"
            src={SolidWorksPNG}
            alt="SolidWorks Icon"
            sx={{ width: dense ? 24 : 40, height: dense ? 24 : 40 }}
          />
        );
      case "stl":
        return <ThreeDRotationIcon fontSize={dense ? "medium" : "large"} />;
      default:
        return <FolderIcon fontSize={dense ? "medium" : "large"} />;
    }
  }

  function handleFileClick(file: File) {
    const ext = file.name.split(".").pop()?.toLowerCase();
    if (ext === "step") {
      setSelectedFile(file);
      setOpenViewer(true);
    } else if (ext === "pdf") {
      // Set selected PDF file and open viewer without triggering download
      setSelectedFile(file);
      setOpenViewer(true);
    } else {
      handleDownload(file.url);
    }
  }

  function handleDownload(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function getDisplayName(fileName: string) {
    if (!dense) return fileName;
    return fileName.replace(/\.[^/.]+$/, ""); // Remove file extension in dense mode
  }

  const handleOpenFile = () => {
    // Specify the file path you want to open
    window.electron.openFile("/Volumes/efs-share/parts/0DnPmvYFsAKwH0Vv62eN")
  };

  return (
    <Box key = "box" sx={{ p: 2 }}>
      <Grid container spacing={dense ? 1 : 2}>
        {files && files.map && files.map((file, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              variant="outlined"
              onClick={() => handleFileClick(file)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: dense ? 1 : 2,
                '&:hover': {
                  boxShadow: 3,
                },
              }}
            >
              {/* Icon or ObjView container with fixed size and consistent padding */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: dense ? 40 : 64,
                  height: dense ? 40 : 64,
                  marginRight: dense ? 0.5 : 1,
                }}
              >
                {getFileIcon(file.name)}
              </Box>
              <CardContent sx={{ flexGrow: 1, padding: 0 }}>
                <Typography variant="body1">{getDisplayName(file.name)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <IconButton onClick={() => setDense(!dense)}>
          <FormatSizeIcon />
        </IconButton>
      </Box>

      {/* Full-Screen Viewer Dialog */}
      <Dialog
        fullScreen
        open={openViewer}
        onClose={() => setOpenViewer(false)}
        sx={{
          backdropFilter: "blur(5px)", // Blur background
        }}
      >
        <AppBar position="relative">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {selectedFile?.name} <a href="#" id="open-file-link" onClick={(e) => { e.preventDefault(); handleOpenFile(); }}>Open</a>
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpenViewer(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {/* Display ObjViewer or PDF Viewer based on file type */}
          {selectedFile && (
            selectedFile.name.split(".").pop()?.toLowerCase() === "pdf" 
              ? <PdfViewer pdfUrl={selectedFile.url} /> 
              : <ObjViewer objUrl={dir + selectedFile.name} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
