import {
  Box,
  Card,
  Checkbox,
  Popper,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ERP from "./ERP";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import dayjs, { Dayjs } from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import ObjectToolTip from "../components/Core/ObjectToolTip";
import { ObjectType } from "./Typings";
import { notificationManager } from "../managers/NotificationManager";

type DynamicPropType = string | "text" | "number" | "date" | "object";
function isNumber(str: string) {
  return !isNaN(Number(str)) && str.trim() !== "";
}

function ColorChip(props: { label: string }) {
  return <Chip label={props.label} color={"primary"} variant="outlined" />;
}

function GetPropType(propName: string, objectType?: ObjectType) {
  switch (propName.toLowerCase()) {
    case "type":
      if (objectType) {
        switch (objectType) {
          case "cot":
            return "select:cot_type";
        }
      }
      return `select:contact_type`;
    case "tax_exempt":
      return "boolean";
    case "redact_shipper":
      return "boolean";
    case "client":
      return "object:contact";
    case "responsible":
      return "object:user";
    case "part":
      return "object:part";
    case "workorder":
      return "object:workorder";
    case "workcell":
      return "object:workcell";
    case "rfq":
      return "object:rfq";
    case "po":
      return "object:po";
    case "cpo":
      return "object:cpo";
    case "due_date":
      return "date";
    case "date":
      return "date";
    case "start":
      return "date";
    case "end":
      return "date";
    case "wo":
      return "object:workorder";
    case "status":
      return "select:status";
    case "product_division":
      return "select:division";
    case "supplier":
      return "object:contact";
    case "aro":
      return "select:aro";
    case "resource":
      return "object:resource,user";
    case "user":
      return "object:user";
    case "permission_level":
      return "select:permissions";
    case "vendor":
      return "object:contact";
    case "role":
      return "select:employee_role";
    default:
      return "text";
  }
}

function GetSelectionOptions(selectType: string): string[] {
  switch (selectType) {
    case "contact_type":
      return ["Client", "Vendor", "Person"];
    case "status":
      return ["Active", "Inactive", "Complete"];
    case "aro":
      return ["1-2 Weeks", "2-3 Weeks", "4-5 Weeks"];
    case "permissions":
      return ["1", "2", "3", "4", "5"];
    case "division":
      return ["1", "2", "3"];
    case "employee_role":
      return ["Operations", "Machinist", "Admin", "Engineer", "Developer"];
    case "cot_type":
      return [
        "RAW MATERIAL",
        "ADHESIVE",
        "ABRASIVES",
        "BATTERY",
        "BEARINGS/BUSHINGS",
        "BEARING",
        "FASTENER",
        "FINISHES/TOUCH-UP",
        "FILTER",
        "GREASE/LUBRICANT",
        "HOUSINGS/BRACKETS",
        "LUBE/OIL/FLUIDS",
        "RAW MATERIAL",
        "PACKAGING",
        "RUBBER",
        "SEALS/O-RINGS/GASKETS",
        "THREADED INSERTS",
      ];
  }
  return [];
}

const CapitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.substring(1, word.length);
};

type DynamicPropProps = {
  name: string;
  value?: string | any;
  editMode?: boolean;
  onEdit?: (newValue: string | number | boolean) => void;
  condensed?: boolean;
  objectType?: ObjectType;
};

type DropdownOption = {
  name: string;
  value: string;
};

const internalProps = ["items", "operations"];

const DynamicProp = (props: DynamicPropProps) => {
  const basePropType = GetPropType(props.name, props.objectType);
  const t = basePropType.split(":")[0];
  const objectType = basePropType.split(":")[1];
  const [dropDownOptions, setDropDownOptions] = useState<DropdownOption[]>([]);
  const [localValue, setLocalValue] = useState<any>(props.value || "");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoverTimer, setHoverTimer] = useState<NodeJS.Timeout | null>(null);
  const [toolTipOpen, setToolTipOpen] = useState(false);

  useEffect(() => {
    setLocalValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    if (objectType) {
      const unionPromises = objectType.split(",").map((objType) => {
        return ERP.GetObjectList(objType);
      });
      Promise.all(unionPromises)
        .then((values) => {
          const options: { name: string; value: string }[] = [];
          values.forEach((tab) => {
            tab.forEach((opt) => {
              options.push({ name: opt.name || opt.id, value: opt.id });
            });
          });
          setDropDownOptions(options);
        })
        .catch((err) => {
          notificationManager.notify("error", err);
        });
    }
  }, [props.value, props.name]);

  const handleHover = () => {
    setToolTipOpen(true);
    // Add your event logic here
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHoverTimer(setTimeout(handleHover, 1000)); // Set a timer for 2 seconds
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setToolTipOpen(false);
    if (hoverTimer) {
      clearTimeout(hoverTimer); // Clear the timer if the mouse leaves
    }
  };

  const condensedView = props.condensed || false;
  const P = (
    <>
      {t === "text" && (
        <TextField
          sx={{ width: "100%" }}
          disabled={!props.editMode}
          value={localValue} // controlled input value
          variant="standard"
          onChange={(event) => {
            setLocalValue(event.target.value); // Update local value as user types
          }}
          onBlur={() => {
            if (props.onEdit && localValue !== props.value) {
              if (isNumber(localValue)) {
                props.onEdit(Number(localValue));
              } else props.onEdit(localValue); // Trigger onEdit only onBlur
            }
          }}
        />
      )}
      {t === "object" && (
        <FormControl fullWidth>
          <NativeSelect
            disabled={!props.editMode}
            value={localValue}
            onChange={(event) => {
              setLocalValue(event.target.value);
              if (props.onEdit) props.onEdit(event.target.value);
            }}
          >
            <option value="" disabled>
              Please select an option
            </option>
            {dropDownOptions.length > 0 ? (
              dropDownOptions.map((opt, i) => (
                <option key={`${i}`} value={opt.value}>
                  {opt.name}
                </option>
              ))
            ) : (
              <CircularProgress />
            )}
          </NativeSelect>
          {!props.editMode && (
            <Link to={`/objects/${objectType}s/${props.value}`}>
              <Box
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0)", // Transparent overlay
                  cursor: "pointer",
                  // Optional styling for visual feedback
                }}
              >
                <Popper
                  open={toolTipOpen}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                >
                  <Box sx={{ maxWidth: "100%" }}>
                    <Card>
                      <ObjectToolTip
                        objectType={objectType as ObjectType}
                        objectId={localValue}
                      />
                    </Card>
                  </Box>
                </Popper>
              </Box>
            </Link>
          )}
        </FormControl>
      )}
      {t === "date" && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {props.editMode ? (
            <DatePicker
              sx={{ width: "100%" }}
              disabled={!props.editMode}
              value={dayjs(localValue)}
              onChange={(date) => {
                if (date) {
                  const isoDate = date.toISOString();
                  console.log(isoDate);
                  setLocalValue(isoDate);
                  if (props.onEdit) props.onEdit(isoDate);
                }
              }}
            />
          ) : (
            <Chip
              color={
                props.name === "date"
                  ? "info"
                  : dayjs(localValue).isAfter(dayjs())
                  ? "success"
                  : "error"
              }
              label={dayjs(localValue)
                .toDate()
                .toLocaleString()
                .substring(0, 10)}
            />
          )}
        </LocalizationProvider>
      )}
      {t === "select" && (
        <FormControl fullWidth sx={{ mt: 1 }}>
          {props.editMode ? (
            <NativeSelect
              disabled={!props.editMode}
              value={localValue}
              onChange={(event) => {
                setLocalValue(event.target.value);
                if (props.onEdit) props.onEdit(event.target.value);
              }}
            >
              <option disabled value="">
                Please select an option
              </option>
              {GetSelectionOptions(objectType).map((option) => (
                <option value={option}>{option}</option>
              ))}
            </NativeSelect>
          ) : (
            <ColorChip label={localValue} />
          )}
        </FormControl>
      )}
      {t === "boolean" && (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!props.editMode}
                defaultChecked={localValue}
                value={localValue}
                onChange={(event) => {
                  setLocalValue(event.target.checked);
                  if (props.onEdit) props.onEdit(event.target.checked);
                }}
              />
            }
            label={
              typeof localValue === "boolean"
                ? localValue === true
                  ? "Yes"
                  : "No"
                : "No"
            }
          />
        </FormControl>
      )}
    </>
  );

  //if (props.name === "tax_exempt") console.log("VALUE: ", typeof localValue, localValue)

  return condensedView ? (
    <>{P}</>
  ) : (
    <Card sx={{ padding: 2, minWidth: 200 }}>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6" component="label" sx={{ marginBottom: 1 }}>
          {props.name.split("_").map((word) => `${CapitalizeWord(word)} `)}
        </Typography>
        {t === "object" && (
          <Link to={`/objects/${objectType}s/${props.value}`}>
            <IconButton
              sx={{ ml: 1 }} // Add some left margin to separate from the text
            >
              <ArrowOutwardIcon />
            </IconButton>
          </Link>
        )}
      </Box>
      {P}
    </Card>
  );
};

export default DynamicProp;
