import { notificationManager } from "../managers/NotificationManager";

export interface EditorProps {};

export default class PropEditor {
    private changes = new Map<string, any>();
    private save?: (changes: Map<string, any>) => boolean;
    constructor(onSave?: (changes: Map<string, any>) => boolean) {
        this.save = onSave;
    }
    TrackChange(propName: string, value: any) {
        console.log("Tracked.")
        this.changes.set(propName, value);
    }
    ChangeCount() {
        return this.changes.size;
    }
    Cancel() {
        this.changes.clear();
    }
    Save() {
        if (this.changes.size > 0) {
            try {
                if (this.save) {
                    //
                    const success = this.save(this.changes);
                    if (success) {
                        notificationManager.notify('success', `Changes saved. (${this.changes.size})`);
                        return;
                    } else throw success;
                } else throw "NO CALLBACK"
            } catch (err) {
                notificationManager.notify('error', `Changes failed to save: ${err}`)
            }
        } else {
            notificationManager.notify('warning', 'No changes to save.');
        }
    }
}