import {
  Box,
  Card,
  Chip,
  IconButton,
  NativeSelect,
  Typography,
} from "@mui/material";
import EditTools from "./EditTools";
import { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material/Select"; // Import SelectChangeEvent
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import DynamicProp from "../../util/DynamicProp";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";
import { WorkCell } from "../../util/Typings";

type FieldName =
  | "opNum"
  | "desc"
  | "resource"
  | "certified"
  | "firstArticle"
  | "totalComplete"
  | "qtyQueuedNextOp"
  | "breakDownComplete";
type Data = {
  opNum: number;
  desc: string;
  resource: string;
  workCell?: string;
  complete?: string;
}[];

export type PartOperationsProps = {
  workOrder: string;
  partId: string;
  readOnly?: boolean;
};
export default function WorkOrderOperations(props: PartOperationsProps) {
  const [isEditing, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<Data>([]);
  const [editIdx, setEditIdx] = useState(-1); // Track the row being edited
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    const getOps = () => {
      if (props.partId) {
        ERP.Parts.GetPartOperations(props.partId)
          .then((ops) => {
            ERP.GetWorkCellsByWO(props.workOrder)
              .then((workcells) => {
                const d = ops.map((op) => {
                  const filteredCells = workcells.filter((c) => {
                    return Number(c.opnum) === Number(op.opNum);
                  });
                  return {
                    ...op,
                    workCell: filteredCells[0]
                      ? filteredCells[0].id
                      : undefined,
                    complete: filteredCells[0]
                      ? `${filteredCells[0].quantity_complete}/${filteredCells[0].quantity_total}`
                      : undefined,
                  };
                });
                setTableData(d);
              })
              .catch((err) => {
                notificationManager.notify(
                  "error",
                  `Failed to load work cells: ${err}`
                );
              });
          })
          .catch((err) => {
            notificationManager.notify("error", err);
          });
      }
    };

    getOps();
  }, [props.partId, props.workOrder]);

  const handleSelectChange = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [fieldName]: value };
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  function handleToggleEditMode(editing: boolean) {
    setEditMode(editing);
  }
  function handleSave() {
    if (editCount > 0) {
      const parsedData = tableData;
      ERP.Parts.UpdatePartOperations(props.partId, parsedData)
        .then((updatedData) => {
          console.log(updatedData);
          notificationManager.notify("success", "Changes saved.");
        })
        .catch((err) => {
          notificationManager.notify(
            "error",
            "Changes could not be saved: " + err
          );
        });
    } else {
      notificationManager.notify("warning", "No changes to save.");
    }
    setEditMode(false);
  }
  return (
    <Card sx={{ width: "100%", marginTop: 2 }}>
      <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            verticalAlign: "bottom",
          }}
        >
          Progress
        </Typography>
        {!props.readOnly && (
          <EditTools
            isEditing={isEditing}
            toggleEdit={handleToggleEditMode}
            onSave={handleSave}
          />
        )}
      </Box>
      <br />
      <TableContainer component={Paper} sx={{}}>
        <Table size="small" sx={{}}>
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "2px solid rgba(0, 0, 0, 0.87)", // Adjust the thickness and color here
              }}
            >
              <TableCell>OP#</TableCell>
              <TableCell>Desc</TableCell>
              <TableCell>Resource</TableCell>
              <TableCell>Work Cell</TableCell>
              <TableCell>Complete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                {/* Editable OP# Cell */}
                <TableCell>{row.opNum}</TableCell>

                {/* Editable Desc Cell */}
                <TableCell>{row.desc}</TableCell>

                {/* Editable Resource Dropdown */}
                <TableCell>
                  <DynamicProp
                    value={row.resource}
                    name="resource"
                    editMode={false}
                    condensed
                    onEdit={(newValue) => {
                      handleSelectChange(index, "resource", newValue as string);
                    }}
                  />
                </TableCell>
                <TableCell>
                  {row.workCell ? (
                    <DynamicProp
                      value={row.workCell}
                      name="workcell"
                      editMode={false}
                      condensed
                      onEdit={(newValue) => {
                        //handleSelectChange(index, "resource", newValue as string);
                      }}
                    />
                  ) : (
                    <Chip color="error" label="none" />
                  )}
                </TableCell>
                <TableCell>
                  <Chip
                    color={
                      Number(row.complete?.split("/")[0]) >=
                      Number(row.complete?.split("/")[1])
                        ? "success"
                        : "error"
                    }
                    label={row.complete || "?"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
