// notificationManager.ts
export type NotificationType = 'success' | 'error' | 'warning' | 'info';

export interface NotificationEvent {
  id: string;
  type: NotificationType;
  message: string;
}

class NotificationManager {
  private listeners: ((notification: NotificationEvent) => void)[] = [];

  // Subscribe to events
  subscribe(listener: (notification: NotificationEvent) => void) {
    this.listeners.push(listener);
  }

  // Unsubscribe from events
  unsubscribe(listener: (notification: NotificationEvent) => void) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  // Emit a new notification event
  notify(type: NotificationType, message: string) {
    const notification: NotificationEvent = {
      id: Math.random().toString(36).substring(2),
      type,
      message,
    };
    this.listeners.forEach((listener) => listener(notification));
  }
}

// Export a singleton instance of NotificationManager
export const notificationManager = new NotificationManager();
