import React, { useState } from 'react';
import { TextField, IconButton, Grid, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, Card, CardContent, CardActions } from '@mui/material';
import { FileCopy as FileCopyIcon, Refresh as RefreshIcon, Visibility, VisibilityOff } from '@mui/icons-material';

const DriveAccess: React.FC = () => {
  const [token, setToken] = useState<string>('HiddenToken12345');
  const [showToken, setShowToken] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(token);
    alert('Token copied to clipboard');
  };

  const handleRefreshConfirm = () => {
    setOpenConfirmDialog(true);
  };

  const handleRefreshCancel = () => {
    setOpenConfirmDialog(false);
  };

  const handleRefresh = () => {
    const newToken = `NewToken${Math.floor(Math.random() * 1000)}`; // Example random new token
    setToken(newToken);
    setOpenConfirmDialog(false);
    alert('Your token has been refreshed and the current token is now invalidated.');
  };

  const toggleTokenVisibility = () => {
    setShowToken(!showToken);
  };

  return (
    <Card variant="outlined" style={{ minWidth: 500, maxWidth: 600, margin: 'auto', padding: '20px' }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Drive Access Token
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={token}
              type={showToken ? 'text' : 'password'}
              InputProps={{ readOnly: true }}
              label="Drive Access Token"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Tooltip title="Copy">
          <IconButton onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={showToken ? "Hide" : "Show"}>
          <IconButton onClick={toggleTokenVisibility}>
            {showToken ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton onClick={handleRefreshConfirm}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </CardActions>

      {/* Confirmation Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleRefreshCancel}>
        <DialogTitle>Confirm Token Refresh</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to refresh the token? This will invalidate your current token.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefreshCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRefresh} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DriveAccess;
