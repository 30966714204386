import * as React from "react";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-charts/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-tree-view/themeAugmentation";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "../components/AppNavbar";
import Header from "../components/Header";

import SideMenu from "../components/SideMenu";
import { useLocation } from "react-router-dom";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "../theme/customizations";
import AppTheme from "../shared-theme/AppTheme";
import ResourcesOverview from "../components/Resources/ResourceOverview";
import ObjectOverview from "../components/Objects/ObjectOverview";
import ObjectViewer from "../components/Objects/ObjectViewer";
import { ObjectType } from "../util/Typings";
import UserCreateWizard from "../components/Core/UserCreate";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function ObjPage(props: {
  objType: ObjectType;
  disableCustomTheme?: boolean;
}) {
  const location = useLocation();
  // add part viewer based on current path
  const path = location.pathname.split("/");
  const isViewingObject = path[path.length - 1] !== `${props.objType}s`;

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.palette.background.default
              ? `rgba(${theme.palette.background.default} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            {isViewingObject ? (
              <ObjectViewer objectType={props.objType} />
            ) : (
              <ObjectOverview objectType={props.objType} />
            )}
            {props.objType === "user" && !isViewingObject && (
              <Box
                sx={{ display: "flex", justifyItems: "left", width: "100%" }}
              >
                <UserCreateWizard />
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
