import * as React from 'react';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-charts/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-tree-view/themeAugmentation';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from '../../theme/customizations/'
import AppTheme from '../../shared-theme/AppTheme';
import AppNavbar from '../AppNavbar';
import Header from '../Header';
import SideMenu from '../SideMenu';
import Copyright from '../../internals/components/Copyright';
import { Chip, Typography } from '@mui/material';
import { Grid } from '@react-three/drei';
import { useUser } from '../Core/UserContext';
import ERP from '../../util/ERP';
import { notificationManager } from '../../managers/NotificationManager';
import TimeClockTable from './TimeClockTable';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function TimeClock(props: { disableCustomTheme?: boolean }) {
    const {user} = useUser();
    const [data, setData] = useState<{time_in: string}[]>([]);
    useEffect(() => {
        const getData = async () => {
            ERP.Users.GetTimeclockSummary().then((summary) => {
                setData(summary)
            }).catch((err) => {
                notificationManager.notify("error", err)
            })
        }

        getData();
    }, []);
    return (
        <AppTheme {...props} themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: 'flex' }}>
            <SideMenu />
            <AppNavbar />
            {/* Main content */}
            <Box 
            component="main"
            sx={(theme) => ({
                flexGrow: 1, 
                backgroundColor: theme.palette.background.default
                ? `rgba(${theme.palette.background.default} / 1)`
                : alpha(theme.palette.background.default, 1),
                overflow: 'auto',
            })}
            >
            <Stack
                spacing={2}
                sx={{
                alignItems: 'center',
                mx: 3,
                pb: 10,
                mt: { xs: 8, md: 0 },
                }}
            >
                <Header />
                <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
                {/* cards */} 
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                    Punch History
                </Typography>
                <TimeClockTable data={data}/>
                <Copyright sx={{ my: 4 }} />
                </Box>
            </Stack>
            </Box>
        </Box>
        </AppTheme>
    );
}
